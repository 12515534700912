<template>
  <div>

      <div
      v-if="blog"
      class="little-blog-card"
      >
        <router-link
        :to="{path:'/details',query:{blogId:blog.id}}"
        style="color: #000000;text-decoration: none;"
        >
           <el-tooltip class="item" effect="dark" placement="top-end">
                 <div slot="content">
                 <h2
                   >{{blog.heading}}</h2>
                   <p
                   style="width: 200px;"
                   >
                   {{blog.quizFlag=='solve'?'这是一条解答':blog.quizFlag=='quiz'?'这是一条提问':blog.synopsis?blog.synopsis:'暂无简介'}}
                   </p>
                 </div>
                 <div>
                   <h4
                     class="card-heading"
                     >{{blog.heading}}</h4>
                     <p
                     class="card-synopsis"
                     >
                     {{blog.quizFlag=='solve'?'这是一条解答':blog.quizFlag=='quiz'?'这是一条提问':blog.synopsis?blog.synopsis:'暂无简介'}}
                     </p>
                 </div>
           </el-tooltip>
           </router-link>
      </div>
      <div
      v-if="!blog"
      class="little-blog-card"
      >

          <el-tooltip class="item" effect="dark" content="无法找到该文章，也许该文章已经被删除了" placement="top-end">
               <h4
               class="card-heading"
               style="padding-bottom: 10px;"
               >无法找到该文章，也许该文章已经被删除了</h4>
         </el-tooltip>
      </div>

  </div>
</template>

<script>
  export default{
    name:'LittleBlogCard',
    props:['blog'],
    data() {
      return {

      }
    },
    methods:{
      link_to_blog(path,blogId,event){
        this.$router.push({path: path, query: {blogId:blogId}})
      },
      link_to_user(path,username){
         this.$emit("restUser",username)
         this.$router.push({path: path, query: {username:username}})
      },
    }
  }
</script>

<style scoped>
  .little-blog-card{
    background-color: #D8D8D8;padding: 0px 10px 0px 15px;
    overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
    cursor: pointer;
    border-radius: 10px;
  }
  .card-heading{
    overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
     margin:10px 0px 0px 0px
  }
  .card-synopsis{
    overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
    font-size: 14px;
  }
</style>
