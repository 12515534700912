<template>
  <div style="padding: 15px;"
  >
    <el-row class="content_row" :gutter="15"
    >
      <el-col :md="{span:10,offset:14}">
        <InputSearchUi label="搜索"
         style="float: right;"
        @searchCond="searchCond"
        ></InputSearchUi>
      </el-col>
    </el-row>
    <el-row
    >
    <el-col
    v-for="(item,index) in dataList"
    style="padding: 20px;
    padding-left: 100px;
    padding-right: 100px;
    "
    :md="24"
    >
      <el-col
      :md="{span:1}" :xs="5"
      >
        <el-avatar
            size="large"
            :src="item.user.avatar"
            @click.stop="link_to_user('/user',item.creator)"
          ></el-avatar>
      </el-col>
      <el-col :md="item.commentType=='replyComment'?{span:10}:{span:19}" :xs="19">
        <div
        @click.stop="link_to_user('/user',item.creator)"
        style="cursor: pointer;"
        >
          <h4 style="margin: 0px;">{{
            item.user.nickname
          }}
          <span
          style="
          font-size: 13px;color: #8D8D8D;
          margin-left: 20px;
          "
          > {{formatTime(new Date(item.createTime).getTime(),'{y}年{m}月{d}日')}}</span>
          </h4>
          <p
          style="font-size: 14px;"
          >
            {{item.comment}}
          </p>
        </div>
      </el-col>
      <el-col :md="9"
      v-if="item.commentType=='replyComment'"
      >
        <h4
          class="card-heading"
        >原评论</h4>
        <p class="card-synopsis">{{item.commentObj?item.commentObj.comment:'原评论已被删除'}}</p>
      </el-col>
      <el-col :md="{span:3}" :xs="24">
        <LittleBlogCard :blog="item.blog!=null?item.blog:item.commentObj!=null?item.commentObj.blog:null">
        </LittleBlogCard>
      </el-col>
      <el-col :md="1" v-if="username==currentPageUsername">
        <LoadingButton style="cursor: pointer;margin-left: 30%;"
        :size="20" v-if="loadingObj[item.id]"></LoadingButton>
        <span style="cursor: pointer;margin-left: 50%;"
        v-if="!loadingObj[item.id]"
        @click.stop="deleteComment(item.id,index,item.id)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </el-col>
      </el-col>
    </el-row>
    <div
    style="height: 100px;width: 100%;">
        <span style="display: none;">{{disabled}}</span>
        <h4 v-if="noMore && !loading"style="color:#BABE99;text-align: center;">没有更多了哦！</h4>
        <div v-loading="loading" style="margin-top: 20px;">
        </div>
    </div>
    <div
    style="height: 450px;"
    v-if="loading || noMore"
    >
    </div>
  </div>
</template>
<script>
import LittleBlogCard from './components/LittleBlogCard'
import InputSearchUi from '@/components/ui/InputSearchUi'
import {getCommentListCurrentUserApi,deleteCommentApi,deleteReplyCommentApi} from '@/api/comment'
import UserInfoCard from '../details/UserInfoCard.vue'
import { parseTime,formatTime } from '@/utils/date'
import { mapGetters } from 'vuex'
export default{
  name:'UserComment',
  components: {
    LittleBlogCard,
    UserInfoCard,
    InputSearchUi
  },
  created() {
    this.load()
  },
  data(){
    return{
      dataList:[],
      totalPage:undefined,
      currentPage:undefined,
      totalNumber:undefined,
      loading:true,
      dataList:[],
      currentPageUsername:this.$route.query.username,
      query:{
        pageNum:1,
        pageSize:12,
        username:this.$route.query.username,
      },
      loadingObj:{}
    }
  },
  computed:{
    ...mapGetters([
      'username'
    ]),
    noMore() {
        return this.totalNumber < this.query.pageSize || this.totalPage===this.currentPage;
    },
    disabled() {
      this.$emit("changeDisabled",this.loading || this.noMore)
      return this.loading || this.noMore
    },
  },
  methods:{
    parseTime,
    formatTime,
    async deleteComment (commentId, index,loading) {
      let ids = [commentId]
      this.$set(this.loadingObj,loading,true)
      let res = await deleteCommentApi(ids)
      if (res.data.success) {
        this.$message({
          message: '成功删除一条评论',
          type: 'success'
        })
        this.dataList.splice(index, 1)
      }
        this.$set(this.loadingObj,loading,false)
    },
    async deleteReplyComment (replyCommentId,index,loading) {
      let ids = [replyCommentId]
      this.$set(this.loadingObj,loading,true)
      let res = await deleteReplyCommentApi(ids)
      if (res.data.success) {
        this.$message({
          message: '成功删除一条评论',
          type: 'success'
        })
        this.dataList.splice(index, 1)
      }
      this.$set(this.loadingObj,loading,false)
    },
    link_to_blog(path,blogId,event){
      this.$router.push({path: path, query: {blogId:blogId}})
    },
    link_to_user(path,username){
       this.$emit("restUser",username)
       this.$router.push({path: path, query: {username:username}})
    },
    restUser(username){
      this.$emit("restUser",username)
    },
    load() {
        this.loading=true
        this.getCommentListCurrentUser()
    },
    restDataList(){
      this.dataList=[]
    },
    searchCond(condition){
      this.restDataList()
      this.query.pageNum=1
      this.query.pageSize=12
      this.query.condition=condition
      this.load()
    },
    async getCommentListCurrentUser(){
     await getCommentListCurrentUserApi(this.query).then((res)=>{
       if(res.data.success){
         const dataListAll=res.data.data.list
         for(let i=0;i<dataListAll.length;++i){
           this.dataList.push(dataListAll[i])
         }

         this.totalNumber=res.data.data.list.length;
         this.loading=false;
         this.query.pageNum++;
         this.totalPage=res.data.data.pages
         this.currentPage=res.data.data.pageNum
       }
     })

    },

  }
}
</script>

<style scoped>
  .content_row{
    width: 100%;
  }
  .card-heading{
    overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
     margin:0px 0px 0px 0px
  }
  .card-synopsis{
    overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
    font-size: 14px;
  }
</style>
