<template>
  <div class="container">
    <el-row>
      <el-col :md="{span:8,offset:8}" style="text-align: center;">
          <div
          style="cursor: pointer;"
          v-if="!userItem.userType||userItem.userType=='sysUser'"
           @click="link_to_user(userItem.username)"
          >
            <el-avatar
            :size="80"
            :src="userItem.avatar"
            class="avatar"
            fit="cover"
            >
            </el-avatar>
          </div>
          <a
          style="cursor: pointer;"
          v-if="userItem.userType&&userItem.userType=='friendshipLink'"
          :href="userItem.link"
          target="_blank"
          >
            <el-avatar
            :size="80"
            :src="userItem.avatar"
            class="avatar"
            fit="cover"
            >
            </el-avatar>
          </a>
      </el-col>
      <el-col :md="{span:24}" style="text-align: center;">
        <!-- <span class="username my_overflow"
        style="width: 100px;height: 30px;line-height: 30px;font-weight:bold;">

        </span> -->
        <h3 style="margin:5px;margin-left:0px;margin-right: 0px;width: 100%;" class="my_overflow">
          {{userItem.nickname}}
        </h3>
      </el-col>
    </el-row>
    <!-- <el-divider>联系方式</el-divider> -->
    <el-row v-if="!userItem.userType||userItem.userType=='sysUser'">
      <el-col class="num":md="{span:12}" :sm="{span:12}" :xs="{span:12}">
        <p>手机号</p>
        <span style="color:#24292E;">{{userItem.phone==null?'暂无':userItem.phone}}</span>
      </el-col>
      <el-col class="num":md="{span:12}" :sm="{span:12}":xs="{span:12}">
        <p>邮箱</p>
        <span style="color:#24292E;">{{userItem.email==null?'暂无':userItem.email}}</span>
      </el-col>
    </el-row>
    <!-- <el-divider>介绍</el-divider> -->
    <el-row style="margin-bottom:15px">
      <el-col :md="{span:20,offset:2}" style="text-align: center;">
        <span style="color:#9B9B9D;font-size: 14px;">
          {{userItem.introduction===null?'该用户还未做任何介绍哦':user.introduction}}
        </span>
      </el-col>
    </el-row>
    <el-row v-if="!userItem.userType||userItem.userType=='sysUser'">
      <el-col class="num" :md="{span:6}" :xs="6">
        <span>{{userItem.praise}}</span><br/>
        <span>获赞</span>
      </el-col>
      <el-col class="num":md="{span:6}" :xs="6">
        <span>{{userItem.blogNum}}</span><br />
        <span>文章</span>
      </el-col>
      <el-col class="num":md="{span:6}" :xs="6">
        <span>{{userItem.glance}}</span><br />
        <span>访问</span>
      </el-col>
      <el-col class="num":md="{span:6}" :xs="6">
        <span>{{userItem.fans}}</span><br />
        <span>粉丝</span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import global from '@/api/global_variable.js'
import {attentionUserApi} from '@/api/relative'
export default{
  name:'userInfoCard',
  props:['user'],
  data(){
    return{
      imgURL:global.imgURL,
      userItem:this.user
    }
  },
  watch: {
    user(newValue, oldValue) {
      this.userItem=newValue
    }
  },
  methods: {
    attentionUser(username){
      attentionUserApi(username).then((res)=>{
        if(res.data.success){
          this.$message({
            type:'success',
            message:'您关注了'+userItem.nickname
          })
        }
      })
    },
    link_to_user(username){
      this.$emit("restUser",username)
      this.$router.push({path: '/user', query: {username:username}})
    },
  },

}
</script>

<style scoped>
  .my_overflow{
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .container{
    background-color: #F5F5F5;
    padding: 10px;
    border-radius: 5px;
  }
  .container .avatar{
    border:3px solid #F5F5F5;
    box-shadow: 0px 0px 5px #969799;
  }
  .container .username{
    font-size: 20px;
  }
  .container .num{
    text-align: center;
    font-size: 14px;
    color:#9B9B9D;
    margin-bottom:10px
  }
</style>
