import request from '@/utils/request'
export const getCommentByBlogApi = (data) => request.get('/public/comment/getCommentByBlogId', {params:data})
export const createComment = (data) => request.post('/comment/create', data,{useToken:true})
export const praiseApi = (data) => request.post('/praiseStep/praise', data,{useToken:true})
export const stepApi = (data) => request.post('/praiseStep/step', data,{useToken:true})
export const createReplyCommentApi = (data) => request.post('/replyComment/create', data,{useToken:true})
export const deleteCommentApi = (data) => request.post('/comment/delete', data,{useToken:true})
export const deleteReplyCommentApi = (data) => request.post('/replyComment/delete', data,{useToken:true})
export const getCommentedOfUserApi = (data) => request.get('/comment/getCommentedOfUser', {params:data,useToken:true})

export function getCommentListCurrentUserApi(query){
  return request({
    url:'/comment/getCommentListByUsername',
    method:'get',
    params:query
  })
}
