import request from '@/utils/request'


export function getMyAttentionApi(query){
  return request({
    url:'/relative/getMyAttention',
    method:'get',
    params:query,
    useToken:true
  })
}
export function getCurrentAttentionApi(query){
  return request({
    url:'/relative/getCurrentAttention',
    method:'get',
    params:query,
    useToken:true
  })
}
export function cancelAttentionApi(username){
  return request({
    url:'/relative/cancelAttention',
    method:'post',
    params:{username:username},
    useToken:true
  })
}
export function attentionUserApi(username){
  return request({
    url:'/relative/attentionUser',
    method:'post',
    params:{username:username},
    useToken:true
  })
}
export function collectBlogApi(blogId){
  return request({
    url:'/relative/collectBlog',
    method:'post',
    params:{blogId:blogId},
    useToken:true
  })
}

export function isAttentionApi(attentionedUser,attentionUser){
  return request({
    url:'/relative/isAttention',
    method:'get',
    params:{attentionedUser:attentionedUser,attentionUser:attentionUser}
  })
}
